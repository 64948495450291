import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import WebComponent from '../../WebComponent.js'
import DossierService from '../Dossier/DossierService.js'
import DossierColumns from '../Dossier/DossierColumns.js'
import DossierDTO from '../Dossier/DossierDTO.js'
import Dossierstatus from '../Dossier/Dossierstatus.js'
import OrganisationService from '../Organisation/OrganisationService.js'
import Meldeart from '../Dossier/Meldeart.js'

export default class Gesendet extends WebComponent {

  get translationFile() {
    return './views/Gesendet/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.url = ''
    this.organisation = []

    const params = getNavigationParams()
    if (params.has('dossierID')) {
      this.redirectToDossierView(params)
    } else {
      this.reload()
    }
  }

  reload() {
    const organisationService = new OrganisationService()
    const dossierService = new DossierService()

    Promise.all([
      organisationService.list(), dossierService.getBackendListUrl('gesendet')
    ])
      .then(([json, url]) => {
        this.url = url
        this.organisation = json.map(entry => ({value: entry.code, name: entry.name}))
        this.render()
      })
      .catch(_ => {
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('gesendet.titel')}"></isceco-title>

      <p>${this.i18n.translate('gesendet.beschreibung')}</p>

      <isceco-filter-group>
        <isceco-string-filter label="${this.i18n.translate('table.header.dossiernummer')}"
                              key="code"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.meldeart')}"
                                   .items="${Meldeart.OPTIONS}"
                                   .i18n="${this.i18n}"
                                   key="art"
        ></isceco-multiselect-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.empfaenger')}"
                                   .items="${this.organisation}"
                                   key="empfaenger"
        ></isceco-multiselect-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.datenfile')}"
                              key="metadaten"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.pdffile')}"
                              key="nutzdaten"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.sendedatum')}"
                            key="created"
        ></isceco-date-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.bearbeitetdatum')}"
                            key="changed"
        ></isceco-date-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.status')}"
                                   .items="${Dossierstatus.OPTIONS}"
                                   .i18n="${this.i18n}"
                                   key="status"
        ></isceco-multiselect-filter>
      </isceco-filter-group>

      <isceco-list id="flamfrontend-gesendet"
                   url=${this.url}
                   .i18n="${this.i18n}"
                   .dtoMapper="${this.toDossier}"
                   .columns="${[
                     DossierColumns.CODE_COLUMN(),
                     DossierColumns.MELDEART_COLUMN(),
                     DossierColumns.EMPFAENGER_COLUMN(this.organisation),
                     DossierColumns.METADATEN_COLUMN({callback: this.downloadMetadatenFile}),
                     DossierColumns.NUTZDATEN_COLUMN({callback: this.downloadNutzdatenFile}),
                     DossierColumns.SENDEDATUM_COLUMN(),
                     DossierColumns.BEARBEITETDATUM_COLUMN(),
                     DossierColumns.STATUS_COLUMN('status.archived')
                   ]}"
                   .defaultFilters="${this.defaultFilters()}"
                   sort="changed"
      ></isceco-list>
    `
  }

  defaultFilters = () => getNavigationParams().size > 0 ? null :
    {status: [Dossierstatus.STATUS_KEYS.HOCHGELADEN, Dossierstatus.STATUS_KEYS.NICHT_ABGEHOLT, Dossierstatus.STATUS_KEYS.ZURUECKGEWIESEN]}

  toDossier = row => new DossierDTO(row)

  redirectToDossierView = params => {
    const dossierId = params.get('dossierID')
    const dossierService = new DossierService()
    dossierService.read(dossierId)
      .then(json => {
        // redirect auf Empfangen, falls das Dossier sich da befindet
        if (json.empfaenger.code === window.keycloak.tokenParsed.unitExtId
          && json.status !== Dossierstatus.STATUS_KEYS.ZURUECKGEWIESEN) {
          navigate({to: 'Empfangen', params: {code: dossierId}})
        } else {
          // query parameter dossierID mit filter.code ersetzen
          navigate({params: {code: dossierId}})
          this.reload()
        }
      })
      .catch(_ => {
        // query parameter dossierID mit filter.code ersetzen
        navigate({params: {code: dossierId}})
        this.reload()
      })
  }

  downloadMetadatenFile = dossier => {
    const dossierService = new DossierService()
    dossierService.download(dossier.code, dossier.metadaten, 'metadaten')
  }

  downloadNutzdatenFile = dossier => {
    const dossierService = new DossierService()
    dossierService.download(dossier.code, dossier.nutzdaten, 'nutzdaten')
  }
}

customElements.define('flam-gesendet', Gesendet)
