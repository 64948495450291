import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/HorizontalNavigation/HorizontalNavigation.js'
import WebComponent from '../../WebComponent.js'
import Navigation from './Navigation.js'
import ViewFilter from './ViewFilter.js'

export default class HorizontalNavigation extends WebComponent {

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => {
      this.reload()
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  get translationFile() {
    return './views/Navigation/i18n.json'
  }

  getTemplate() {
    const allItems = {
      Organisation: {text: this.i18n.translate('navigation.organisation'), icon: 'users', url: 'Organisation', suburls: ['OrganisationDetail']},
      Dossier: {text: this.i18n.translate('navigation.dossier'), icon: 'list alternate', url: 'Dossier'},
      Senden: {text: this.i18n.translate('navigation.senden'), icon: 'paper plane', url: 'Senden'},
      Gesendet: {text: this.i18n.translate('navigation.gesendet'), icon: 'envelope', url: 'Gesendet'},
      Empfangen: {text: this.i18n.translate('navigation.empfangen'), icon: 'inbox', url: 'Empfangen'},
      About: {text: this.i18n.translate('navigation.about'), icon: 'question circle', url: 'About'}
    }
    const items = new ViewFilter().viewsAllowed(Object.keys(allItems)).map(key => allItems[key])
    return html`
      <isceco-horizontal-navigation
        .items="${items}">
      </isceco-horizontal-navigation>
    `
  }
}

customElements.define('flam-navigation', HorizontalNavigation)
