import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Link/Link.js'

export default class LinkCell {
  constructor(configuration) {
    this.type = configuration.type
  }

  render(i18n, cell) {
    return html`
      <isceco-link text="${cell}"
                   url="${this.formatHref(cell)}"
                   @click="${e => e.stopPropagation()}"
      ></isceco-link>
    `
  }

  formatHref(cell) {
    if (isEmpty(cell)) {
      return ''
    } else {
      return `${this.type}:${cell}`
    }
  }
}
