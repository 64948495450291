import OrganisationDTO from '../Organisation/OrganisationDTO.js'
import Dossierstatus from './Dossierstatus'

export default class DossierDTO {

  constructor(json) {
    if (json) {
      this.json = json
      this.code = json.code
      this.verarbeitet = json.status === Dossierstatus.STATUS_KEYS.VERARBEITET
      this.empfaengerDTO = new OrganisationDTO(json.empfaenger)
      this.senderDTO = new OrganisationDTO(json.sender)
      this.art = json.art
      this.metadaten = json.metadaten
      this.metadatenShort = shortenFilename(json.metadaten, 20)
      this.nutzdaten = json.nutzdaten
      this.nutzdatenShort = shortenFilename(json.nutzdaten, 20)
      this.created = json.created
      this.changed = json.changed
      this.status = json.status
      this.zurueckgewiesen_grund = json.zurueckgewiesen_grund
    } else {
      this.json = {}
      this.empfaengerDTO = new OrganisationDTO()
      this.senderDTO = new OrganisationDTO()
    }
  }

  get empfaenger() {
    return this.empfaengerDTO.code
  }

  get sender() {
    return this.senderDTO.code
  }
}
