import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import CheckboxColumn from '@isceco/widget-library2/basic-elements/List/column/CheckboxColumn.js'
import ButtonCell from '@isceco/widget-library2/basic-elements/List/cell/ButtonCell.js'
import LinkCell from '../User/LinkCell.js'

export default class OrganisationColumns {

  static KENNUNG_COLUMN() {
    return new StringColumn({
      key: 'code',
      text: 'organisation.organisation.kennung',
      sortable: true,
      weight: 1
    })
  }

  static NAME_COLUMN() {
    return new StringColumn({
      key: 'name',
      text: 'organisation.organisation.name',
      sortable: true,
      weight: 2
    })
  }

  static EMAIL_COLUMN() {
    return new GenericColumn({
      key: 'email',
      text: 'organisation.organisation.email',
      cell: new LinkCell({type: 'mailto'}),
      sortable: true,
      weight: 2
    })
  }

  static TELEFON_COLUMN() {
    return new GenericColumn({
      key: 'telefon',
      text: 'organisation.organisation.telefon',
      cell: new LinkCell({type: 'tel'}),
      sortable: true,
      weight: 2
    })
  }

  static AKTIV_COLUMN() {
    return new CheckboxColumn({
      key: 'aktiv',
      text: 'organisation.organisation.aktiv',
      disabled: _ => true,
      sortable: true,
      weight: 1
    })
  }

  static BEARBEITEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'bearbeiten',
      text: 'organisation.organisation.bearbeiten',
      cell: new ButtonCell({
        callback: configuration.callback,
        text: 'organisation.organisation.bearbeiten'
      }),
      weight: 1
    })
  }

  static LOESCHEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'loeschen',
      text: 'organisation.organisation.loeschen',
      cell: new ButtonCell({
        callback: configuration.callback,
        disabled: configuration.disabled,
        text: 'organisation.organisation.loeschen'
      }),
      weight: 1
    })
  }
}
