import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import LinkCell from './LinkCell.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'

export default class UserColumns {

  static VORNAME_COLUMN() {
    return new StringColumn({
      key: 'vorname',
      text: 'user.vorname',
      sortable: true
    })
  }

  static NAME_COLUMN() {
    return new StringColumn({
      key: 'name',
      text: 'user.name',
      sortable: true
    })
  }

  static EMAIL_COLUMN() {
    return new GenericColumn({
      key: 'email',
      text: 'user.email',
      cell: new LinkCell({type: 'mailto'}),
      sortable: true
    })
  }

  static TELEFON_COLUMN() {
    return new GenericColumn({
      key: 'telefon',
      text: 'user.telefon',
      cell: new LinkCell({type: 'tel'}),
      sortable: true
    })
  }
}
